<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-center">
      <div class="cost-title">
        Мин. сумма заказа:
      </div>
      <VChipGroup
        v-model="selectedConditionIndexes.orderMinCost"
        active-class="cost-selected"
        mandatory
      >
        <VChip
          v-for="cost in orderMinCostChoices"
          :key="cost"
          class="cost-chip justify-center"
        >
          {{ cost }}
        </VChip>
        <EditDeliveryModal
          :show="showDialogOrderMinCost"
          :title="title"
          :type="type"
          @appearance="showDialogOrderMinCost = $event"
          @save="saveConditionCost"
        />
        <VBtn
          class="align-self-center default-btn white--text"
          color="#4caf50"
          elevation="0"
          fab
          rounded
          x-small
          @click="createOrderMinCost"
        >
          <VIcon>mdi-plus-circle</VIcon>
        </VBtn>
      </VChipGroup>
    </div>
    <div class="d-flex align-center">
      <div class="cost-title">
        Доставка:
      </div>
      <VChipGroup
        v-model="selectedConditionIndexes.deliveryCost"
        active-class="cost-selected"
        mandatory
      >
        <VChip
          v-for="cost in deliveryCostChoices"
          :key="cost"
          class="cost-chip justify-center"
        >
          {{ cost }}
        </VChip>
        <EditDeliveryModal
          :show="showDialogDeliveryCost"
          :title="title"
          :type="type"
          @appearance="showDialogDeliveryCost = $event"
          @save="saveConditionCost"
        />
        <VBtn
          class="align-self-center default-btn white--text"
          color="#4caf50"
          elevation="0"
          fab
          rounded
          x-small
          @click="createDeliveryCost"
        >
          <VIcon>mdi-plus-circle</VIcon>
        </VBtn>
      </VChipGroup>
    </div>
  </div>
</template>

<script>
import EditDeliveryModal from '@/components/EditDeliveryModal.vue';
import { DeliveryCondition } from '@/serializers/deliveryConditionsSerializer';
import { createConditionCost, fetchConditionCosts } from '@/api/api';

const orderMinCostChoicesDefault = [
  0, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 7000, 9999,
];
const deliveryCostChoicesDefault = [
  0, 50, 150, 250, 350, 500, 600, 700, 800, 900, 1000, 1200, 1300, 1500,
];

export default {
  name: 'EditDeliveryCondition',
  components: { EditDeliveryModal },
  props: {
    value: {
      type: DeliveryCondition,
      default() {
        return new DeliveryCondition();
      },
    },
  },
  emits: ['input'],
  created() {
    this.fetchCosts();
    this.setupDefaults();
  },
  data() {
    return {
      title: '',
      type: '',
      showDialogOrderMinCost: false,
      showDialogDeliveryCost: false,
      selectedConditionIndexes: {
        orderMinCost: 0,
        deliveryCost: 0,
      },
      orderMinCostChoices: [],
      deliveryCostChoices: [],
    };
  },
  methods: {
    setupDefaults() {
      this.selectedConditionIndexes.orderMinCost = this.orderMinCostChoices
        .indexOf(this.value.orderMinCost);
      this.selectedConditionIndexes.deliveryCost = this.deliveryCostChoices
        .indexOf(this.value.deliveryCost);
    },
    createOrderMinCost() {
      this.title = 'Создание мин. суммы заказа';
      this.type = 'MIN_ORDER';
      this.showDialogOrderMinCost = true;
    },
    createDeliveryCost() {
      this.title = 'Создание стоимости доставки';
      this.type = 'DELIVERY';
      this.showDialogDeliveryCost = true;
    },
    async saveConditionCost(conditionCost) {
      await createConditionCost(conditionCost.deserialized);
      this.fetchCosts();
    },
    fetchCosts() {
      fetchConditionCosts().then((result) => {
        this.orderMinCostChoices = [];
        this.deliveryCostChoices = [];
        const costTypes = {
          MIN_ORDER: (cost) => { this.orderMinCostChoices.push(cost); },
          DELIVERY: (cost) => { this.deliveryCostChoices.push(cost); },
        };
        result.forEach((costElement) => {
          if (costTypes[costElement.type]) {
            costTypes[costElement.type](costElement.cost);
          }
        });
      }).catch(() => {
        this.orderMinCostChoices = orderMinCostChoicesDefault;
        this.deliveryCostChoices = deliveryCostChoicesDefault;
      });
    },
  },
  watch: {
    value() {
      this.setupDefaults();
    },
    selectedConditionIndexes: {
      deep: true,
      handler(newIndexes) {
        const newCondition = new DeliveryCondition();
        newCondition.orderMinCost = this.orderMinCostChoices[newIndexes.orderMinCost];
        newCondition.deliveryCost = this.deliveryCostChoices[newIndexes.deliveryCost];
        this.$emit('input', newCondition);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/mixin.scss';

.cost-title {
  font-size: 12px;
  width: 110px;
  margin-right: 10px;
}
.cost-chip {
  width: 70px;
}
.cost-selected {
  color: white !important;
  background: $default_green_color !important;
}
::v-deep{
  .v-slide-group__content{
    flex-wrap: wrap;
    flex: auto;
  }
}
</style>
